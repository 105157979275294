import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"
import HorairesHeader from "../../components/horairesHeader"

const HorairesPage = () => (
  <Layout>
    <Seo title="Les horaires" breadcrumb={[
      { url: "les-horaires", name: "Les horaires"},
    ]} />
    <Breadcrumb paths={[{url: null, name:"Les horaires"}]} />

    <HorairesHeader active={0} />
    <p className="opacity-50 text-lg">Veuillez sélectionner une option ci-dessus.</p>
  </Layout>
)

export default HorairesPage
